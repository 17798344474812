body #CybotCookiebotDialog #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  background-color: rgb(var(--sys-primary-primary));
  border-color: rgb(var(--sys-primary-primary));
  color: rgb(var(--sys-primary-on-primary));
}

#CybotCookiebotDialog
  #CybotCookiebotDialogNav
  .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-color: transparent;
  border-bottom: 1px solid rgb(var(--sys-secondary-secondary));
  color: rgb(var(--sys-secondary-secondary) / 0.8);
}

#CybotCookiebotDialog #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
  transition: color 0.3s;
  border-bottom-color: rgba(var(--sys-secondary-secondary), 0.8);
  color: rgb(var(--sys-secondary-secondary) / 0.8);
}

#CookieDeclarationChangeConsent a {
  color: var(--sys-secondary-secondary);
  text-decoration: underline;
}

#CookieDeclarationChangeConsent a:hover {
  color: rgb(var(--sys-secondary-secondary) / 0.8);
}

body #CybotCookiebotDialog input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: rgb(21, 239, 119);
}

body #CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: rgb(var(--ref-primary-primary30));
}

body #CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSlider:hover {
  background-color: rgb(var(--ref-primary-primary30) / 0.8);
}

/* Disabled */
body
  #CybotCookiebotDialog
  form
  input[type='checkbox'][disabled]:checked
  + .CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: rgb(var(--sys-surface-surface-container));
}

body
  #CybotCookiebotDialog
  input:checked
  + .CybotCookiebotDialogBodyLevelButtonSlider:hover {
  background-color: rgba(21, 239, 119, 0.8);
}

#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a,
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink,
#CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a,
#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a,
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABDescription a,
#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink,
#CybotCookiebotDialogDetailBodyContentTextAbout a {
  color: rgb(var(--sys-secondary-secondary)) !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a:hover,
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink:hover,
#CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a:hover,
#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a:hover,
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABDescription a:hover,
#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink:hover,
#CybotCookiebotDialogDetailBodyContentTextAbout a:hover {
  color: rgb(var(--sys-secondary-secondary) / 0.8) !important;
}
