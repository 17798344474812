/* 
The global.css file integrates Tailwind CSS styles and adds additional global custom styles.
*/

/* Import Tailwind CSS base, components, and utilities */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Utility class to hide scrollbars in various browsers */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*this class will set in NavigationSubMenu*/
.submenu-overlay::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.25);
}

/* Custom style to add an outline to text */
.strokeHeadline {
  -webkit-text-stroke-width: 2;
  -webkit-text-stroke-color: var(--sys-primary-primary, #1a1a1a);
}

/* Import custom colors from the colors.css file; these are directly exported from figma */
@layer base {
  @import './colors.css';
}

/* Define utility classes for consistent container layouts */
@layer utilities {
  .container-content {
    @apply w-full xl:max-w-screen-xl 2xl:max-w-screen-2xl;
    @apply px-xl md:px-4xl xl:px-4xl mx-auto;
  }

  .container-content-desktop {
    @apply lg:w-full xl:max-w-screen-xl 2xl:max-w-screen-2xl;
    @apply lg:px-4xl xl:px-4xl lg:mx-auto;
  }

  /* Remove top margin from Instagram posts that appear directly after headings */
  h2 + .hihInstagramPost,
  h3 + .hihInstagramPost,
  h4 + .hihInstagramPost {
    margin-top: 0;
  }
}
