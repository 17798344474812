/* 
primitives directly exported from figma
*/

:root {
  /* M3 */
  /* colors */
  --black: 0 0 0;
  --white: 255 255 255;
  --ref-error-error0: 0 0 0;
  --ref-error-error10: 65 0 2;
  --ref-error-error100: 255 255 255;
  --ref-error-error20: 105 0 5;
  --ref-error-error30: 147 0 10;
  --ref-error-error40: 186 26 26;
  --ref-error-error50: 222 55 48;
  --ref-error-error60: 255 84 73;
  --ref-error-error70: 255 137 125;
  --ref-error-error80: 255 180 171;
  --ref-error-error90: 255 218 214;
  --ref-error-error95: 255 237 234;
  --ref-error-error99: 255 251 255;
  --ref-neutral-neutral0: 0 0 0;
  --ref-neutral-neutral10: 27 27 27;
  --ref-neutral-neutral100: 255 255 255;
  --ref-neutral-neutral12: 31 31 31;
  --ref-neutral-neutral17: 42 42 42;
  --ref-neutral-neutral20: 51 51 51;
  --ref-neutral-neutral22: 56 56 56;
  --ref-neutral-neutral24: 61 61 61;
  --ref-neutral-neutral30: 82 82 82;
  --ref-neutral-neutral4: 14 14 14;
  --ref-neutral-neutral40: 101 101 101;
  --ref-neutral-neutral50: 124 124 124;
  --ref-neutral-neutral6: 19 19 19;
  --ref-neutral-neutral60: 152 152 152;
  --ref-neutral-neutral70: 189 189 189;
  --ref-neutral-neutral80: 204 204 204;
  --ref-neutral-neutral87: 220 220 220;
  --ref-neutral-neutral90: 233 233 233;
  --ref-neutral-neutral92: 237 237 237;
  --ref-neutral-neutral94: 240 240 240;
  --ref-neutral-neutral95: 242 242 242;
  --ref-neutral-neutral96: 245 245 245;
  --ref-neutral-neutral98: 250 250 250;
  --ref-neutral-neutral99: 252 252 252;
  --ref-neutral-variant-neutral-variant0: 0 0 0;
  --ref-neutral-variant-neutral-variant10: 24 24 27;
  --ref-neutral-variant-neutral-variant100: 255 255 255;
  --ref-neutral-variant-neutral-variant20: 39 39 42;
  --ref-neutral-variant-neutral-variant30: 63 63 70;
  --ref-neutral-variant-neutral-variant40: 82 82 91;
  --ref-neutral-variant-neutral-variant50: 113 113 122;
  --ref-neutral-variant-neutral-variant60: 161 161 170;
  --ref-neutral-variant-neutral-variant70: 212 212 216;
  --ref-neutral-variant-neutral-variant80: 228 228 231;
  --ref-neutral-variant-neutral-variant90: 244 244 245;
  --ref-neutral-variant-neutral-variant95: 250 250 250;
  --ref-neutral-variant-neutral-variant99: 252 252 252;
  --ref-primary-primary0: 0 0 0;
  --ref-primary-primary10: 26 26 26;
  --ref-primary-primary100: 255 255 255;
  --ref-primary-primary20: 51 51 51;
  --ref-primary-primary30: 77 77 77;
  --ref-primary-primary40: 102 102 102;
  --ref-primary-primary50: 128 128 128;
  --ref-primary-primary60: 153 153 153;
  --ref-primary-primary70: 178 178 178;
  --ref-primary-primary80: 204 204 204;
  --ref-primary-primary90: 229 229 229;
  --ref-primary-primary95: 243 243 243;
  --ref-primary-primary99: 252 252 252;
  --ref-secondary-secondary0: 0 0 0;
  --ref-secondary-secondary10: 146 10 10;
  --ref-secondary-secondary100: 255 255 255;
  --ref-secondary-secondary20: 177 3 3;
  --ref-secondary-secondary30: 215 0 0;
  --ref-secondary-secondary40: 238 0 0;
  --ref-secondary-secondary5: 80 0 0;
  --ref-secondary-secondary50: 255 35 35;
  --ref-secondary-secondary60: 255 87 87;
  --ref-secondary-secondary70: 255 148 148;
  --ref-secondary-secondary80: 255 192 192;
  --ref-secondary-secondary90: 255 221 221;
  --ref-secondary-secondary95: 255 240 240;
  --ref-secondary-secondary99: 255 250 250;
  --ref-tertiary-tertiary0: 0 0 0;
  --ref-tertiary-tertiary10: 25 65 133;
  --ref-tertiary-tertiary100: 255 255 255;
  --ref-tertiary-tertiary20: 24 73 169;
  --ref-tertiary-tertiary30: 23 92 211;
  --ref-tertiary-tertiary40: 21 112 239;
  --ref-tertiary-tertiary50: 46 144 250;
  --ref-tertiary-tertiary60: 83 177 253;
  --ref-tertiary-tertiary70: 132 202 255;
  --ref-tertiary-tertiary80: 178 221 255;
  --ref-tertiary-tertiary90: 209 233 255;
  --ref-tertiary-tertiary95: 239 248 255;
  --ref-tertiary-tertiary99: 245 250 255;
}

/**
 * dark mode is not part of v1 currently we use light mode variant as default.
 * switch works automatically by activating the media queries below.
 */

/* generic variables to match the required primitive depending on light/dark mode */
/* @media (prefers-color-scheme: light { */
:root {
  --sys-error-error: var(--ref-error-error40);
  --sys-error-error-container: var(--ref-error-error90);
  --sys-error-on-error: var(--ref-error-error100);
  --sys-error-on-error-container: var(--ref-error-error10);
  --sys-inverse-inverse-on-surface: var(--ref-neutral-neutral95);
  --sys-inverse-inverse-primary: var(--ref-primary-primary80);
  --sys-inverse-inverse-surface: var(--ref-neutral-neutral20);
  --sys-outline-outline: var(--ref-neutral-variant-neutral-variant40);
  --sys-outline-outline-variant: var(--ref-neutral-variant-neutral-variant80);
  --sys-primary-on-primary: var(--ref-primary-primary100);
  --sys-primary-on-primary-container: var(--ref-primary-primary10);
  --sys-primary-on-primary-fixed: var(--ref-primary-primary100);
  --sys-primary-on-primary-fixed-variant: var(--ref-primary-primary30);
  --sys-primary-primary: var(--ref-primary-primary10);
  --sys-primary-primary-container: var(--ref-primary-primary90);
  --sys-primary-primary-fixed: var(--ref-primary-primary10);
  --sys-primary-primary-fixed-dim: var(--ref-primary-primary80);
  --sys-secondary-on-secondary: var(--ref-secondary-secondary100);
  --sys-secondary-on-secondary-container: var(--ref-secondary-secondary10);
  --sys-secondary-on-secondary-fixed: var(--ref-secondary-secondary100);
  --sys-secondary-on-secondary-fixed-variant: var(--ref-secondary-secondary30);
  --sys-secondary-secondary: var(--ref-secondary-secondary40);
  --sys-secondary-secondary-container: var(--ref-secondary-secondary90);
  --sys-secondary-secondary-fixed: var(--ref-secondary-secondary40);
  --sys-secondary-secondary-fixed-dim: var(--ref-secondary-secondary80);
  --sys-shadow-scrim-scrim: var(--ref-neutral-neutral0);
  --sys-shadow-scrim-shadow: var(--ref-neutral-neutral0);
  --sys-surface-on-surface: var(--ref-neutral-neutral10);
  --sys-surface-on-surface-variant: var(--ref-neutral-variant-neutral-variant30);
  --sys-surface-surface: var(--ref-neutral-neutral98);
  --sys-surface-surface-bright: var(--ref-neutral-neutral98);
  --sys-surface-surface-container: var(--ref-neutral-neutral94);
  --sys-surface-surface-container-high: var(--ref-neutral-neutral92);
  --sys-surface-surface-container-highest: var(--ref-neutral-neutral90);
  --sys-surface-surface-container-low: var(--ref-neutral-neutral96);
  --sys-surface-surface-container-lowest: var(--ref-neutral-neutral100);
  --sys-surface-surface-dim: var(--ref-neutral-neutral87);
  --sys-surface-surface-variant: var(--ref-neutral-variant-neutral-variant95);
  --sys-tertiary-on-tertiary: var(--ref-tertiary-tertiary100);
  --sys-tertiary-on-tertiary-container: var(--ref-tertiary-tertiary10);
  --sys-tertiary-on-tertiary-fixed: var(--ref-tertiary-tertiary100);
  --sys-tertiary-on-tertiary-fixed-variant: var(--ref-tertiary-tertiary30);
  --sys-tertiary-tertiary: var(--ref-tertiary-tertiary40);
  --sys-tertiary-tertiary-container: var(--ref-tertiary-tertiary90);
  --sys-tertiary-tertiary-fixed: var(--ref-tertiary-tertiary40);
  --sys-tertiary-tertiary-fixed-dim: var(--ref-tertiary-tertiary80);
}
/* } */

/* @media (prefers-color-scheme: dark {
  :root {
    --sys-error-error: var(--ref-error-error80);
    --sys-error-error-container: var(--ref-error-error30);
    --sys-error-on-error: var(--ref-error-error20);
    --sys-error-on-error-container: var(--ref-error-error90);
    --sys-inverse-inverse-on-surface: var(--ref-neutral-neutral20;
    --sys-inverse-inverse-primary: var(--ref-primary-primary40;
    --sys-inverse-inverse-surface: var(--ref-neutral-neutral90;
    --sys-outline-outline: var(--ref-neutral-variant-neutral-variant60;
    --sys-outline-outline-variant: var(--ref-neutral-variant-neutral-variant30;
    --sys-primary-on-primary: var(--ref-primary-primary20;
    --sys-primary-on-primary-container: var(--ref-primary-primary90;
    --sys-primary-on-primary-fixed: var(--ref-primary-primary100;
    --sys-primary-on-primary-fixed-variant: var(--ref-primary-primary30;
    --sys-primary-primary: var(--ref-primary-primary80;
    --sys-primary-primary-container: var(--ref-primary-primary30;
    --sys-primary-primary-fixed: var(--ref-primary-primary10;
    --sys-primary-primary-fixed-dim: var(--ref-primary-primary80;
    --sys-secondary-on-secondary: var(--ref-secondary-secondary20;
    --sys-secondary-on-secondary-container: var(--ref-secondary-secondary90;
    --sys-secondary-on-secondary-fixed: var(--ref-secondary-secondary100;
    --sys-secondary-on-secondary-fixed-variant: var(--ref-secondary-secondary30;
    --sys-secondary-secondary: var(--ref-secondary-secondary80;
    --sys-secondary-secondary-container: var(--ref-secondary-secondary30;
    --sys-secondary-secondary-fixed: var(--ref-secondary-secondary40;
    --sys-secondary-secondary-fixed-dim: var(--ref-secondary-secondary80;
    --sys-shadow-scrim-scrim: var(--ref-neutral-neutral0;
    --sys-shadow-scrim-shadow: var(--ref-neutral-neutral0;
    --sys-surface-on-surface: var(--ref-neutral-neutral90;
    --sys-surface-on-surface-variant: var(--ref-neutral-variant-neutral-variant80;
    --sys-surface-surface: var(--ref-neutral-neutral6;
    --sys-surface-surface-bright: var(--ref-neutral-neutral24;
    --sys-surface-surface-container: var(--ref-neutral-neutral12;
    --sys-surface-surface-container-high: var(--ref-neutral-neutral17;
    --sys-surface-surface-container-highest: var(--ref-neutral-neutral22;
    --sys-surface-surface-container-low: var(--ref-neutral-neutral10;
    --sys-surface-surface-container-lowest: var(--ref-neutral-neutral4;
    --sys-surface-surface-dim: var(--ref-neutral-neutral6;
    --sys-surface-surface-variant: var(--ref-neutral-variant-neutral-variant10;
    --sys-tertiary-on-tertiary: var(--ref-tertiary-tertiary20;
    --sys-tertiary-on-tertiary-container: var(--ref-tertiary-tertiary90;
    --sys-tertiary-on-tertiary-fixed: var(--ref-tertiary-tertiary100;
    --sys-tertiary-on-tertiary-fixed-variant: var(--ref-tertiary-tertiary30;
    --sys-tertiary-tertiary: var(--ref-tertiary-tertiary80;
    --sys-tertiary-tertiary-container: var(--ref-tertiary-tertiary30;
    --sys-tertiary-tertiary-fixed: var(--ref-tertiary-tertiary40;
    --sys-tertiary-tertiary-fixed-dim: var(--ref-tertiary-tertiary80;
  }
} */
